import React, { useState } from 'react';
import { Campaign } from '../../types';
import { Calendar, DollarSign, ChevronDown, ChevronUp, Building2 } from 'lucide-react';

interface CampaignCardProps {
  campaign: Campaign;
  onAccept?: () => void;
  onApprove?: () => void;
  onReject?: () => void;
  onClick?: () => void;
  showStatus?: boolean;
  compact?: boolean;
  brandName?: string;
}

export function CampaignCard({ 
  campaign, 
  onAccept,
  onApprove,
  onReject,
  onClick,
  showStatus,
  compact = false,
  brandName = 'Brand Name'
}: CampaignCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    if (compact) {
      setIsExpanded(!isExpanded);
    } else if (onClick) {
      onClick();
    }
  };

  if (compact) {
    return (
      <div 
        className="glass-card p-4 cursor-pointer hover:bg-gray-800/50 transition-all"
        onClick={handleClick}
      >
        <div className="flex items-center justify-between">
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-2 mb-1">
              <Building2 className="w-4 h-4 text-gray-400" />
              <span className="text-sm text-gray-400">{brandName}</span>
            </div>
            <h3 className="font-medium text-white truncate">{campaign.title}</h3>
            <div className="flex items-center gap-4 mt-1 text-sm text-gray-400">
              <span className="capitalize">{campaign.contentType}</span>
              <span className="flex items-center gap-1">
                <DollarSign className="w-4 h-4" />
                {campaign.rewardAmount}
              </span>
              <span className="flex items-center gap-1">
                <Calendar className="w-4 h-4" />
                {new Date(campaign.deadline).toLocaleDateString()}
              </span>
            </div>
          </div>
          <button className="ml-4 text-gray-400">
            {isExpanded ? (
              <ChevronUp className="w-5 h-5" />
            ) : (
              <ChevronDown className="w-5 h-5" />
            )}
          </button>
        </div>

        {isExpanded && (
          <div className="mt-4 space-y-4 border-t border-gray-700 pt-4">
            <p className="text-gray-300">{campaign.description}</p>

            {campaign.requirements && campaign.requirements.length > 0 && (
              <div>
                <h4 className="text-sm font-medium text-gray-400 mb-2">Requirements:</h4>
                <ul className="list-disc list-inside text-gray-300 text-sm space-y-1">
                  {campaign.requirements.map((req, index) => (
                    <li key={index}>{req}</li>
                  ))}
                </ul>
              </div>
            )}

            <div className="flex justify-end">
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  onAccept?.();
                }}
                className="button-primary"
              >
                Apply Now
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div 
      className={`glass-card p-6 space-y-4 transition-all duration-200 ${
        onClick ? 'cursor-pointer hover:bg-gray-800/50 hover:scale-[1.02] hover:shadow-lg' : ''
      }`}
      onClick={onClick}
    >
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-xl font-bold gradient-text">{campaign.title}</h3>
          <p className="text-sm text-gray-400">Campaign ID: {campaign.id}</p>
        </div>
        {showStatus && campaign.status && (
          <span className={`px-3 py-1 rounded-full text-sm ${
            campaign.status === 'active'
              ? 'bg-green-500/10 text-green-400'
              : campaign.status === 'draft'
              ? 'bg-gray-500/10 text-gray-400'
              : 'bg-yellow-500/10 text-yellow-400'
          }`}>
            {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
          </span>
        )}
      </div>

      <p className="text-gray-300">{campaign.description}</p>

      <div className="grid grid-cols-2 gap-4 text-gray-400">
        <div className="flex items-center gap-2">
          <DollarSign className="w-4 h-4" />
          <span>${campaign.rewardAmount} per task</span>
        </div>
        <div className="flex items-center gap-2">
          <Calendar className="w-4 h-4" />
          <span>Due: {new Date(campaign.deadline).toLocaleDateString()}</span>
        </div>
      </div>

      {campaign.requirements && campaign.requirements.length > 0 && (
        <div>
          <h4 className="font-medium mb-2">Requirements:</h4>
          <ul className="list-disc list-inside text-gray-300 text-sm space-y-1">
            {campaign.requirements.map((req, index) => (
              <li key={index}>{req}</li>
            ))}
          </ul>
        </div>
      )}

      {(onAccept || onApprove || onReject) && (
        <div className="flex justify-end gap-2">
          {onAccept && (
            <button 
              onClick={(e) => {
                e.stopPropagation();
                onAccept();
              }}
              className="button-primary"
            >
              Apply Now
            </button>
          )}
          {onApprove && (
            <button 
              onClick={(e) => {
                e.stopPropagation();
                onApprove();
              }}
              className="button-primary"
            >
              Approve
            </button>
          )}
          {onReject && (
            <button 
              onClick={(e) => {
                e.stopPropagation();
                onReject();
              }}
              className="px-4 py-2 bg-red-600 hover:bg-red-500 text-white rounded-lg"
            >
              Reject
            </button>
          )}
        </div>
      )}
    </div>
  );
}
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Shield, Lock, Eye, Database, Bell, Globe, Scale, Clock } from 'lucide-react';
import { PageTitle } from '../components/layout/PageTitle';

export function PrivacyPolicyPage() {
  return (
    <div className="min-h-screen bg-gray-900 py-12">
      <PageTitle 
        title="Privacy Policy"
        description="Learn how Sootra Hub collects, uses, and protects your personal information"
      />

      <div className="container mx-auto px-4 max-w-4xl">
        {/* Header */}
        <div className="mb-8">
          <Link
            to="/"
            className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors mb-6"
          >
            <ArrowLeft className="w-5 h-5" />
            Back to Home
          </Link>
          
          <div className="flex items-center gap-3 mb-2">
            <Shield className="w-8 h-8 text-indigo-400" />
            <h1 className="text-3xl font-bold">Privacy Policy</h1>
          </div>
          <p className="text-gray-400">Last updated: {new Date().toLocaleDateString()}</p>
        </div>

        {/* Content */}
        <div className="space-y-8">
          {/* Introduction */}
          <section className="glass-card p-6">
            <p className="text-gray-300">
              At Sootra Hub, we take your privacy seriously. This policy describes how we collect,
              use, and protect your personal information when you use our platform.
            </p>
          </section>

          {/* Information Collection */}
          <section className="glass-card p-6">
            <div className="flex items-center gap-3 mb-4">
              <Database className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Information We Collect</h2>
            </div>
            <div className="space-y-4 text-gray-300">
              <h3 className="font-medium text-white">Account Information</h3>
              <ul className="list-disc list-inside space-y-2 ml-4">
                <li>Email address and username</li>
                <li>Profile information and preferences</li>
                <li>Payment and billing information</li>
                <li>Social media handles and engagement metrics</li>
              </ul>

              <h3 className="font-medium text-white mt-6">Usage Data</h3>
              <ul className="list-disc list-inside space-y-2 ml-4">
                <li>Platform activity and interactions</li>
                <li>Campaign participation and performance</li>
                <li>Content submissions and reviews</li>
                <li>Communication history</li>
              </ul>
            </div>
          </section>

          {/* How We Use Information */}
          <section className="glass-card p-6">
            <div className="flex items-center gap-3 mb-4">
              <Eye className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">How We Use Your Information</h2>
            </div>
            <div className="space-y-4 text-gray-300">
              <ul className="list-disc list-inside space-y-2 ml-4">
                <li>Provide and improve our services</li>
                <li>Process payments and transactions</li>
                <li>Match creators with relevant campaigns</li>
                <li>Send important updates and notifications</li>
                <li>Analyze platform usage and trends</li>
                <li>Prevent fraud and ensure platform security</li>
              </ul>
            </div>
          </section>

          {/* Data Protection */}
          <section className="glass-card p-6">
            <div className="flex items-center gap-3 mb-4">
              <Lock className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Data Protection</h2>
            </div>
            <div className="space-y-4 text-gray-300">
              <p>
                We implement industry-standard security measures to protect your data:
              </p>
              <ul className="list-disc list-inside space-y-2 ml-4">
                <li>End-to-end encryption for sensitive data</li>
                <li>Regular security audits and updates</li>
                <li>Secure payment processing</li>
                <li>Access controls and authentication</li>
              </ul>
            </div>
          </section>

          {/* Data Sharing */}
          <section className="glass-card p-6">
            <div className="flex items-center gap-3 mb-4">
              <Globe className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Information Sharing</h2>
            </div>
            <div className="space-y-4 text-gray-300">
              <p>We may share your information with:</p>
              <ul className="list-disc list-inside space-y-2 ml-4">
                <li>Campaign partners (with your consent)</li>
                <li>Service providers who assist our operations</li>
                <li>Legal authorities when required by law</li>
              </ul>
              <p className="mt-4">
                We never sell your personal information to third parties.
              </p>
            </div>
          </section>

          {/* Your Rights */}
          <section className="glass-card p-6">
            <div className="flex items-center gap-3 mb-4">
              <Scale className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Your Rights</h2>
            </div>
            <div className="space-y-4 text-gray-300">
              <p>You have the right to:</p>
              <ul className="list-disc list-inside space-y-2 ml-4">
                <li>Access your personal information</li>
                <li>Request corrections or deletions</li>
                <li>Export your data</li>
                <li>Opt-out of marketing communications</li>
                <li>Delete your account</li>
              </ul>
            </div>
          </section>

          {/* Data Retention */}
          <section className="glass-card p-6">
            <div className="flex items-center gap-3 mb-4">
              <Clock className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Data Retention</h2>
            </div>
            <div className="space-y-4 text-gray-300">
              <p>
                We retain your information for as long as your account is active or as needed to provide services.
                If you delete your account, we will delete or anonymize your data within 30 days, except where
                we are legally required to maintain certain records.
              </p>
            </div>
          </section>

          {/* Updates */}
          <section className="glass-card p-6">
            <div className="flex items-center gap-3 mb-4">
              <Bell className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Policy Updates</h2>
            </div>
            <div className="space-y-4 text-gray-300">
              <p>
                We may update this policy periodically. We will notify you of any significant changes
                via email or through the platform. Your continued use of Sootra Hub after such changes
                constitutes acceptance of the updated policy.
              </p>
            </div>
          </section>

          {/* Contact */}
          <section className="glass-card p-6">
            <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
            <p className="text-gray-300">
              If you have questions about this privacy policy or your data, please contact us at{' '}
              <a href="mailto:connect@sootra.xyz" className="text-indigo-400 hover:text-indigo-300">
                connect@sootra.xyz
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

import { Campaign, User, Task, CampaignApplication } from '../types';

export const mockUsers: User[] = [
  {
    id: '1',
    email: 'admin@test.com',
    username: 'admin',
    role: 'admin',
    balance: 0,
    created_at: new Date().toISOString(),
    social_links: {}
  },
  {
    id: '2',
    email: 'creator@test.com',
    username: 'creator',
    role: 'promoter',
    balance: 250,
    created_at: new Date().toISOString(),
    social_links: {
      instagram: '@creator',
      twitter: '@creator'
    }
  },
  {
    id: '3',
    email: 'brand@test.com',
    username: 'brand',
    role: 'brand',
    balance: 5000,
    created_at: new Date().toISOString(),
    social_links: {
      website: 'https://brand.com'
    }
  }
];

export const mockCampaigns: Campaign[] = [
  {
    id: '1',
    brandId: 'brand-1',
    title: 'Summer Collection Launch',
    description: 'Share our new summer collection on your Instagram feed.',
    rewardAmount: 50,
    contentType: 'instagram',
    deadline: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
    status: 'active',
    budget: 1000,
    remainingBudget: 950
  },
  {
    id: '2',
    brandId: 'brand-2',
    title: 'Product Review Campaign',
    description: 'Create an authentic review of our skincare products.',
    rewardAmount: 75,
    contentType: 'video',
    deadline: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString(),
    status: 'active',
    budget: 1500,
    remainingBudget: 1500
  }
];

export const mockApplications: CampaignApplication[] = [
  {
    id: '1',
    campaignId: '1',
    promoterId: 'creator-1',
    status: 'pending',
    message: 'I would love to promote your brand. I have experience creating engaging content for similar products.',
    created_at: new Date().toISOString(),
    reviewed_at: null,
    reviewer_id: null,
    feedback: null
  }
];

export const mockSubmissions = [
  {
    id: '1',
    taskId: 'task-1',
    campaignId: '1',
    promoterId: 'creator-1',
    content: 'https://instagram.com/p/example-post',
    status: 'pending',
    submittedAt: new Date().toISOString(),
    preview: {
      type: 'image',
      url: 'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?w=800'
    },
    creator: {
      name: 'Sarah Johnson',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150'
    }
  },
  {
    id: '2',
    taskId: 'task-2',
    campaignId: '1',
    promoterId: 'creator-2',
    content: 'https://tiktok.com/@example/video-post',
    status: 'pending',
    submittedAt: new Date(Date.now() - 3600000).toISOString(),
    preview: {
      type: 'video',
      url: 'https://example.com/video-thumbnail.jpg'
    },
    creator: {
      name: 'Mike Chen',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150'
    }
  }
];

export const mockTasks: Task[] = [
  {
    id: '1',
    campaignId: '1',
    promoterId: 'creator-1',
    status: 'pending',
    proofUrl: 'https://instagram.com/p/123',
    submittedAt: new Date().toISOString(),
    creator: {
      name: 'Sarah Johnson',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150'
    }
  }
];
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Header } from './components/layout/Header';
import { LandingPage } from './pages/LandingPage';
import { DashboardPage } from './pages/DashboardPage';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
import { useAuthStore } from './stores/authStore';

export default function App() {
  const { user, checkSession, signOut } = useAuthStore();

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <div className="min-h-screen bg-gray-900">
          <Header user={user} onSignOut={signOut} />
          <main className="pt-16">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/dashboard/*" element={<DashboardPage />} />
               <Route path="/privacy" element={<PrivacyPolicyPage />} />
            </Routes>
          </main>
        </div>
      </BrowserRouter>
    </HelmetProvider>
  );
}

import { create } from 'zustand';
import type { User } from '../types';

interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
  signIn: (email: string, password: string) => Promise<void>;
  signInAsDemo: (role: 'brand' | 'promoter') => Promise<void>;
  signOut: () => Promise<void>;
  checkSession: () => Promise<void>;
}

// Demo users for instant access
const demoUsers: Record<string, User> = {
  brand: {
    id: 'demo-brand',
    email: 'demo.brand@sootrahub.com',
    username: 'DemoBrand',
    role: 'brand',
    balance: 5000,
    created_at: new Date().toISOString(),
    social_links: {
      website: 'https://example.com'
    }
  },
  promoter: {
    id: 'demo-promoter',
    email: 'demo.creator@sootrahub.com',
    username: 'DemoCreator',
    role: 'promoter',
    balance: 250,
    created_at: new Date().toISOString(),
    social_links: {
      instagram: '@democreator',
      twitter: '@democreator'
    }
  }
};

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  loading: false,
  error: null,

  checkSession: async () => {
    try {
      const storedUser = localStorage.getItem('mockUser');
      if (storedUser) {
        set({ user: JSON.parse(storedUser), loading: false, error: null });
      } else {
        set({ user: null, loading: false, error: null });
      }
    } catch (error) {
      console.error('Session check error:', error);
      localStorage.removeItem('mockUser');
      set({ user: null, loading: false, error: null });
    }
  },

  signIn: async (email: string, password: string) => {
    try {
      set({ loading: true, error: null });
      
      // For demo purposes, redirect to landing page
      window.location.href = '/';
      return;
      
    } catch (error) {
      set({ error: 'Please use the demo login buttons on the homepage', user: null });
      throw new Error('Please use the demo login buttons on the homepage');
    } finally {
      set({ loading: false });
    }
  },

  signInAsDemo: async (role: 'brand' | 'promoter') => {
    try {
      set({ loading: true, error: null });
      
      // Simulate API delay
      await new Promise(resolve => setTimeout(resolve, 500));

      const demoUser = demoUsers[role];
      if (!demoUser) {
        throw new Error('Invalid demo user type');
      }

      // Store in localStorage for session persistence
      localStorage.setItem('mockUser', JSON.stringify(demoUser));
      set({ user: demoUser, error: null });
    } catch (error) {
      set({ error: 'Failed to load demo account', user: null });
      throw new Error('Failed to load demo account');
    } finally {
      set({ loading: false });
    }
  },

  signOut: async () => {
    try {
      set({ loading: true, error: null });
      localStorage.removeItem('mockUser');
      set({ user: null, error: null });
      window.location.href = '/';
    } catch (error) {
      set({ error: 'Failed to sign out', user: null });
      throw new Error('Failed to sign out');
    } finally {
      set({ loading: false });
    }
  }
}));
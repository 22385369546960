import React from 'react';
import { DollarSign, ArrowUpRight, Wallet } from 'lucide-react';

interface WalletCardProps {
  balance: number;
  onRequestPayout: () => void;
  minPayoutAmount: number;
}

export function WalletCard({ balance, onRequestPayout, minPayoutAmount }: WalletCardProps) {
  return (
    <div className="glass-card p-8 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-indigo-600/10 to-purple-600/10"></div>
      <div className="relative">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <div className="p-2 rounded-lg bg-gradient-to-br from-indigo-500/20 to-purple-500/20">
              <Wallet className="w-6 h-6 text-indigo-400" />
            </div>
            <h3 className="text-xl font-bold">Your Balance</h3>
          </div>
          <div className="flex items-center gap-2">
            <span className="px-3 py-1 rounded-full text-sm bg-green-500/10 text-green-400 flex items-center gap-1">
              <ArrowUpRight className="w-4 h-4" />
              +12% this month
            </span>
          </div>
        </div>

        <div className="flex items-end gap-2 mb-6">
          <DollarSign className="w-8 h-8 text-indigo-400" />
          <span className="text-4xl font-bold gradient-text">{balance.toLocaleString()}</span>
        </div>

        <div className="flex items-center justify-between">
          <p className="text-sm text-gray-400">
            {balance < minPayoutAmount ? (
              `Minimum payout amount: $${minPayoutAmount}`
            ) : (
              'Available for withdrawal'
            )}
          </p>
          <button
            onClick={onRequestPayout}
            disabled={balance < minPayoutAmount}
            className="button-primary"
          >
            Request Payout
          </button>
        </div>
      </div>
    </div>
  );
}
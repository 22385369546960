import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LineChart, Line, AreaChart, Area, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { User, Campaign } from '../../types';
import { CampaignList } from '../campaign/CampaignList';
import { BudgetSummary } from './BudgetSummary';
import { DashboardNav } from './DashboardNav';
import { PaymentDialog } from '../payment/PaymentDialog';
import { Plus, Users, FileCheck, ClipboardList, DollarSign, TrendingUp, Target } from 'lucide-react';
import { mockCampaigns } from '../../lib/mockData';

interface BrandDashboardProps {
  user: User;
}

// Mock performance data
const generatePerformanceData = (days: number) => {
  return Array.from({ length: days }, (_, i) => ({
    date: new Date(Date.now() - (days - i - 1) * 24 * 60 * 60 * 1000).toISOString(),
    engagement: Math.random() * 10 + 5, // 5-15%
    reach: Math.floor(Math.random() * 50000 + 10000), // 10k-60k
    conversions: Math.floor(Math.random() * 500 + 100), // 100-600
  }));
};

export function BrandDashboard({ user }: BrandDashboardProps) {
  const navigate = useNavigate();
  const [campaigns] = useState<Campaign[]>(mockCampaigns);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState<'engagement' | 'reach' | 'conversions'>('engagement');
  const [timeframe, setTimeframe] = useState('30');
  const [performanceData] = useState(() => generatePerformanceData(Number(timeframe)));

  const activeCampaigns = campaigns.filter(c => c.status === 'active');
  const pendingReviews = 8; // Mock data

  const formatMetricValue = (value: number) => {
    if (selectedMetric === 'engagement') return `${value.toFixed(1)}%`;
    if (selectedMetric === 'reach') return `${(value / 1000).toFixed(1)}k`;
    return value.toLocaleString();
  };

  const handleAddFunds = async (paymentMethodId: string) => {
    try {
      console.log('Processing payment with method:', paymentMethodId);
      setShowPaymentDialog(false);
    } catch (error) {
      console.error('Payment error:', error);
    }
  };

  const handleCampaignClick = (campaign: Campaign) => {
    navigate(`/dashboard/campaigns/${campaign.id}`);
  };

  return (
    <div className="space-y-8">
      {/* Navigation */}
      <DashboardNav user={user} />

      {/* Quick Stats */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="glass-card p-6">
          <div className="flex items-center gap-3 mb-2">
            <FileCheck className="w-5 h-5 text-indigo-400" />
            <h3 className="text-lg font-semibold">Active Campaigns</h3>
          </div>
          <p className="text-3xl font-bold gradient-text">{activeCampaigns.length}</p>
          <p className="text-sm text-gray-400 mt-2">Currently running</p>
        </div>

        <div className="glass-card p-6">
          <div className="flex items-center gap-3 mb-2">
            <ClipboardList className="w-5 h-5 text-indigo-400" />
            <h3 className="text-lg font-semibold">Pending Reviews</h3>
          </div>
          <p className="text-3xl font-bold gradient-text">{pendingReviews}</p>
          <p className="text-sm text-gray-400 mt-2">Awaiting approval</p>
        </div>

        <div className="glass-card p-6">
          <div className="flex flex-col">
            <div className="flex items-center gap-3 mb-2">
              <DollarSign className="w-5 h-5 text-indigo-400" />
              <h3 className="text-lg font-semibold">Available Balance</h3>
            </div>
            <p className="text-3xl font-bold gradient-text mb-2">${user.balance?.toLocaleString()}</p>
            <button 
              onClick={() => setShowPaymentDialog(true)}
              className="button-primary mt-2"
            >
              Add Funds
            </button>
          </div>
        </div>
      </div>

      {/* Budget Overview */}
      <BudgetSummary 
        totalBudget={campaigns.reduce((sum, campaign) => sum + (campaign.budget || 0), 0)}
        spentBudget={campaigns.reduce((sum, campaign) => sum + ((campaign.budget || 0) - (campaign.remainingBudget || 0)), 0)}
        activeCampaigns={activeCampaigns.length}
      />

      {/* Performance Chart */}
      <div className="glass-card p-6 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-indigo-600/5 to-purple-600/5"></div>
        <div className="relative">
          <div className="flex items-center justify-between mb-8">
            <div>
              <h2 className="text-2xl font-bold mb-2">Campaign Performance</h2>
              <p className="text-gray-400">Track your campaign metrics over time</p>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex gap-2">
                {[
                  { key: 'engagement', icon: Target, label: 'Engagement Rate', color: 'text-emerald-400', gradient: 'from-emerald-500 to-teal-500' },
                  { key: 'reach', icon: Users, label: 'Total Reach', color: 'text-blue-400', gradient: 'from-blue-500 to-indigo-500' },
                  { key: 'conversions', icon: DollarSign, label: 'Conversions', color: 'text-purple-400', gradient: 'from-purple-500 to-pink-500' }
                ].map(({ key, icon: Icon, label, color, gradient }) => (
                  <motion.button
                    key={key}
                    onClick={() => setSelectedMetric(key as any)}
                    className={`px-6 py-3 rounded-xl flex items-center gap-2 transition-all duration-300 relative overflow-hidden ${
                      selectedMetric === key
                        ? `bg-gray-800 ${color} ring-1 ring-gray-700`
                        : 'bg-gray-800/50 text-gray-400 hover:text-white hover:bg-gray-800'
                    }`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    {selectedMetric === key && (
                      <motion.div
                        className={`absolute inset-0 bg-gradient-to-r ${gradient} opacity-10`}
                        layoutId="metricHighlight"
                      />
                    )}
                    <Icon className="w-5 h-5" />
                    <span className="hidden lg:inline relative z-10">{label}</span>
                  </motion.button>
                ))}
              </div>
              <select
                value={timeframe}
                onChange={(e) => setTimeframe(e.target.value)}
                className="px-4 py-2 bg-gray-800 rounded-lg border border-gray-700 focus:ring-2 focus:ring-indigo-500"
              >
                <option value="7">Last 7 days</option>
                <option value="30">Last 30 days</option>
                <option value="90">Last 90 days</option>
              </select>
            </div>
          </div>

          {/* Main Chart */}
          <div className="h-[400px] mt-8">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={performanceData}>
                <defs>
                  <linearGradient id="colorMetric" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={
                        selectedMetric === 'engagement' ? '#10B981' :
                        selectedMetric === 'reach' ? '#3B82F6' :
                        '#8B5CF6'
                      }
                      stopOpacity={0.3}
                    />
                    <stop
                      offset="95%"
                      stopColor={
                        selectedMetric === 'engagement' ? '#10B981' :
                        selectedMetric === 'reach' ? '#3B82F6' :
                        '#8B5CF6'
                      }
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="date"
                  tickFormatter={(date) => new Date(date).toLocaleDateString(undefined, { 
                    month: 'short',
                    day: 'numeric'
                  })}
                  stroke="#6B7280"
                  tickLine={false}
                  axisLine={false}
                  dy={10}
                />
                <YAxis
                  tickFormatter={(value) => formatMetricValue(value)}
                  stroke="#6B7280"
                  tickLine={false}
                  axisLine={false}
                  dx={-10}
                />
                <Tooltip
                  content={({ active, payload }) => {
                    if (!active || !payload?.length) return null;
                    return (
                      <div className="bg-gray-800 border border-gray-700 rounded-lg p-3 shadow-xl">
                        <p className="text-sm text-gray-300 mb-1">
                          {new Date(payload[0].payload.date).toLocaleDateString(undefined, {
                            month: 'long',
                            day: 'numeric'
                          })}
                        </p>
                        <p className="text-lg font-bold text-white">
                          {formatMetricValue(payload[0].value as number)}
                        </p>
                      </div>
                    );
                  }}
                />
                <Area
                  type="monotone"
                  dataKey={selectedMetric}
                  stroke={
                    selectedMetric === 'engagement' ? '#10B981' :
                    selectedMetric === 'reach' ? '#3B82F6' :
                    '#8B5CF6'
                  }
                  strokeWidth={2}
                  fill="url(#colorMetric)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>

          {/* Stats Summary */}
          <div className="grid grid-cols-4 gap-6 mt-8">
            {[
              {
                label: 'Average',
                value: formatMetricValue(
                  Math.round(
                    performanceData.reduce((acc, curr) => acc + curr[selectedMetric], 0) / 
                    performanceData.length
                  )
                ),
                trend: '+5.2%'
              },
              {
                label: 'Highest',
                value: formatMetricValue(Math.max(...performanceData.map(d => d[selectedMetric]))),
                trend: 'Peak'
              },
              {
                label: 'Lowest',
                value: formatMetricValue(Math.min(...performanceData.map(d => d[selectedMetric]))),
                trend: 'Valley'
              },
              {
                label: 'Current',
                value: formatMetricValue(performanceData[performanceData.length - 1][selectedMetric]),
                trend: '+2.1%'
              }
            ].map((stat, index) => (
              <div key={index} className="bg-gray-800/50 rounded-lg p-4">
                <p className="text-sm text-gray-400 mb-1">{stat.label}</p>
                <p className="text-2xl font-bold gradient-text">{stat.value}</p>
                <p className="text-sm text-green-400 mt-1 flex items-center gap-1">
                  <TrendingUp className="w-4 h-4" />
                  {stat.trend}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Campaign Management */}
      <div className="glass-card p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">Campaign Management</h2>
          <button className="button-primary">
            <Plus className="w-5 h-5" />
            New Campaign
          </button>
        </div>

        <CampaignList 
          campaigns={campaigns}
          showStatus
          onCampaignClick={handleCampaignClick}
        />
      </div>

      {/* Payment Dialog */}
      <PaymentDialog
        isOpen={showPaymentDialog}
        onClose={() => setShowPaymentDialog(false)}
        onSubmit={handleAddFunds}
        amount={1000}
        paymentMethods={[
          {
            id: 'pm_1',
            type: 'bank_transfer',
            details: {
              accountName: 'John Doe',
              accountNumber: '****1234',
              bankName: 'Chase'
            },
            isDefault: true
          }
        ]}
        isBrand={true}
      />
    </div>
  );
}
import React from 'react';
import { Campaign } from '../../types';
import { CampaignCard } from './CampaignCard';
import { mockUsers } from '../../lib/mockData';

interface CampaignListProps {
  campaigns: Campaign[];
  onAccept?: (campaignId: string) => void;
  onApprove?: (campaignId: string) => void;
  onReject?: (campaignId: string) => void;
  onCampaignClick?: (campaign: Campaign) => void;
  showStatus?: boolean;
  compact?: boolean;
}

export function CampaignList({ 
  campaigns,
  onAccept,
  onApprove,
  onReject,
  onCampaignClick,
  showStatus = false,
  compact = false
}: CampaignListProps) {
  if (campaigns.length === 0) {
    return (
      <div className="text-center py-8 text-gray-400">
        No campaigns available at this time.
      </div>
    );
  }

  return (
    <div className={`space-y-${compact ? '2' : '4'}`}>
      {campaigns.map((campaign) => {
        const brand = mockUsers.find(u => u.id === campaign.brandId);
        return (
          <CampaignCard
            key={campaign.id}
            campaign={campaign}
            brandName={brand?.username}
            onAccept={onAccept ? () => onAccept(campaign.id) : undefined}
            onApprove={onApprove ? () => onApprove(campaign.id) : undefined}
            onReject={onReject ? () => onReject(campaign.id) : undefined}
            onClick={onCampaignClick ? () => onCampaignClick(campaign) : undefined}
            showStatus={showStatus}
            compact={compact}
          />
        );
      })}
    </div>
  );
}
import React from 'react';
import { DollarSign, TrendingUp, Calendar } from 'lucide-react';

interface BudgetSummaryProps {
  totalBudget: number;
  spentBudget: number;
  activeCampaigns: number;
}

export function BudgetSummary({ totalBudget, spentBudget, activeCampaigns }: BudgetSummaryProps) {
  const remainingBudget = totalBudget - spentBudget;
  const spentPercentage = (spentBudget / totalBudget) * 100;

  return (
    <div className="glass-card p-8 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-indigo-600/10 to-purple-600/10"></div>
      <div className="relative">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h3 className="text-xl font-bold mb-2">Budget Overview</h3>
            <p className="text-sm text-gray-400">Track your campaign spending</p>
          </div>
          <select className="px-4 py-2 bg-gray-800 rounded-lg border border-gray-700">
            <option>This Month</option>
            <option>Last 3 Months</option>
            <option>This Year</option>
          </select>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <div className="flex items-center gap-2 mb-2">
              <DollarSign className="w-5 h-5 text-indigo-400" />
              <span className="text-gray-400">Total Budget</span>
            </div>
            <p className="text-3xl font-bold gradient-text">${totalBudget.toLocaleString()}</p>
            <div className="mt-4">
              <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
                <div
                  className="h-full bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full transition-all duration-500"
                  style={{ width: `${spentPercentage}%` }}
                />
              </div>
              <p className="text-sm text-gray-400 mt-2">
                ${spentBudget.toLocaleString()} spent
              </p>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2 mb-2">
              <TrendingUp className="w-5 h-5 text-indigo-400" />
              <span className="text-gray-400">Remaining Budget</span>
            </div>
            <p className="text-3xl font-bold gradient-text">
              ${remainingBudget.toLocaleString()}
            </p>
            <p className="text-sm text-gray-400 mt-4">
              Available for new campaigns
            </p>
          </div>

          <div>
            <div className="flex items-center gap-2 mb-2">
              <Calendar className="w-5 h-5 text-indigo-400" />
              <span className="text-gray-400">Active Campaigns</span>
            </div>
            <p className="text-3xl font-bold gradient-text">{activeCampaigns}</p>
            <p className="text-sm text-gray-400 mt-4">
              Currently running
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, ChevronDown, CheckCircle2, DollarSign, Users, Megaphone, Shield } from 'lucide-react';
import { useAuthStore } from '../stores/authStore';
import { UserRole } from '../types';

export function LandingPage() {
  const navigate = useNavigate();
  const { signInAsDemo } = useAuthStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState<UserRole>('promoter');
  const [isOpen, setIsOpen] = useState(false);

  const handleDemoLogin = async (role: 'brand' | 'promoter') => {
    try {
      setIsSubmitting(true);
      await signInAsDemo(role);
      navigate('/dashboard');
    } catch (error) {
      console.error('Demo login failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleWaitlistSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);

      // Submit to Netlify forms
      const formData = new FormData();
      formData.append('form-name', 'waitlist');
      formData.append('email', email);
      formData.append('name', name);
      formData.append('role', role);

      const url = '/.netlify/functions/join-waitlist';
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ email, name, role }),
      };

      const response = await fetch(url, options);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to add to the waitlist. Please try again.');
      }

      // Clear form
      setEmail('');
      setName('');
      setRole('promoter');
      alert('Thanks for joining our waitlist! Try our demo to see what\'s coming.');
    } catch (error) {
      console.error('Waitlist submission failed:', error);
      alert((error as Error).message || 'Failed to join waitlist. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-20 px-6 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
        <div className="container mx-auto text-center relative">
          <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 blur-3xl"></div>

          <h1 className="text-5xl md:text-7xl font-bold mb-6 relative">
            The Future of
            <span className="block gradient-text animate-gradient">Creator Marketing</span>
          </h1>

          <p className="text-xl text-gray-300 mb-12 max-w-3xl mx-auto">
            Join the waitlist for early access to the most innovative creator marketplace.
            Connect with authentic creators and launch impactful campaigns.
          </p>

          {/* Waitlist Form */}
          <form
            onSubmit={handleWaitlistSubmit}
            className="max-w-md mx-auto mb-12"
            data-netlify="true"
            name="waitlist"
          >
            <input type="hidden" name="form-name" value="waitlist" />

            <div className="glass-card p-6 space-y-4">
              <div>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your Name"
                  className="w-full px-4 py-3 bg-gray-800 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email"
                  className="w-full px-4 py-3 bg-gray-800 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>
              <div className="flex items-center space-x-2">
                <label htmlFor="role" className="text-lg font-medium text-gray-300 text-left text-nowrap">
                  Join us as
                </label>
                <div className="relative w-full">
                  <button
                    type="button"
                    className="w-full px-4 py-3 bg-gray-800 rounded-lg text-white flex items-center justify-between focus:outline-none focus:ring focus:ring-blue-500"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {role ? (role === 'promoter' ? 'Content Creator' : 'Brand') : 'Select Role'}
                    <ChevronDown className="w-5 h-5 text-gray-400" />
                  </button>

                  {isOpen && (
                    <div className="absolute right-0 left-0 z-10 mt-2 bg-gray-800 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <ul className="py-1">
                        <li>
                          <button
                            type="button"
                            onClick={() => { setRole('promoter'); setIsOpen(false); }}
                            className="w-full px-4 py-2 text-left text-sm text-white hover:bg-gray-700"
                          >
                            Content Creator
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => { setRole('brand'); setIsOpen(false); }}
                            className="w-full px-4 py-2 text-left text-sm text-white hover:bg-gray-700"
                          >
                            Brand
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full button-primary py-3"
              >
                {isSubmitting ? (
                  <div className="flex items-center justify-center gap-2">
                    <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                    Joining...
                  </div>
                ) : (
                  <>Join Waitlist <ArrowRight className="w-5 h-5" /></>
                )}
              </button>
            </div>
          </form>

          {/* Demo Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 justify-center max-w-md mx-auto">
            <button
              onClick={() => handleDemoLogin('brand')}
              className="button-primary flex-1"
              disabled={isSubmitting}
            >
              Try Brand Demo <ArrowRight className="w-5 h-5" />
            </button>
            <button
              onClick={() => handleDemoLogin('promoter')}
              className="button-secondary flex-1"
              disabled={isSubmitting}
            >
              Try Creator Demo <Users className="w-5 h-5" />
            </button>
          </div>
        </div>
      </section >

      {/* Features Section */}
      < section className="py-20 px-6" >
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold text-center mb-12">
            Why Choose <span className="gradient-text">Sootra Hub</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="glass-card p-6">
              <Shield className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-bold mb-2">Verified Creators</h3>
              <p className="text-gray-300">
                Work with authentic creators who deliver real engagement and results.
              </p>
            </div>
            <div className="glass-card p-6">
              <Users className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-bold mb-2">Smart Matching</h3>
              <p className="text-gray-300">
                Our AI matches you with the perfect creators for your brand and goals.
              </p>
            </div>
            <div className="glass-card p-6">
              <CheckCircle2 className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-bold mb-2">Quality Assurance</h3>
              <p className="text-gray-300">
                Every piece of content is reviewed to maintain high standards.
              </p>
            </div>
            <div className="glass-card p-6">
              <DollarSign className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-bold mb-2">Secure Payments</h3>
              <p className="text-gray-300">
                Escrow payments and transparent pricing for peace of mind.
              </p>
            </div>
          </div>
        </div>
      </section >

      {/* CTA Section */}
      < section className="py-20 px-6" >
        <div className="container mx-auto text-center">
          <div className="glass-card p-12 relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 blur-3xl"></div>
            <h2 className="text-4xl font-bold mb-6 relative">
              Ready to Get <span className="gradient-text">Started?</span>
            </h2>
            <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
              Join Sootra Hub today and be part of the future of creator marketing.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={() => handleDemoLogin('brand')}
                className="button-primary"
                disabled={isSubmitting}
              >
                Try Brand Demo <ArrowRight className="w-5 h-5" />
              </button>
              <button
                onClick={() => handleDemoLogin('promoter')}
                className="button-secondary"
                disabled={isSubmitting}
              >
                Try Creator Demo <Users className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </section >

      {/* Footer */}
      < footer className="py-12 px-6 bg-gray-900" >
        <div className="container mx-auto">
          <div className="flex items-center justify-center gap-2 mb-8">
            <div className="p-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg">
              <Megaphone className="w-6 h-6 text-white" />
            </div>
            <span className="text-xl font-bold gradient-text">Sootra Hub</span>
          </div>
          <div className="text-center text-gray-400">
            <p>&copy; {new Date().getFullYear()} Sootra Hub. All rights reserved.</p>
          </div>
        </div>
      </footer >
    </div >
  );
}
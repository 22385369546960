import React from 'react';
import { User } from '../../types';
import { CampaignList } from '../campaign/CampaignList';
import { WalletCard } from './WalletCard';
import { TrendingUp, Award, Target, Clock } from 'lucide-react';

interface UserDashboardProps {
  user: User;
}

// Mock campaign data
const mockCampaigns = [
  {
    id: '1',
    brandId: 'brand-1',
    title: 'Summer Collection Launch',
    description: 'Share our new summer collection on your Instagram feed.',
    rewardAmount: 200,
    contentType: 'instagram',
    deadline: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
    status: 'active',
    requirements: ['Min 5k followers', 'Fashion niche', 'High engagement rate'],
    budget: 2000,
    remainingBudget: 1800
  },
  {
    id: '2',
    brandId: 'brand-2',
    title: 'Product Review Campaign',
    description: 'Create an authentic review of our skincare products.',
    rewardAmount: 150,
    contentType: 'video',
    deadline: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString(),
    status: 'active',
    requirements: ['Video content', 'Beauty niche', 'Previous review experience'],
    budget: 1500,
    remainingBudget: 1500
  }
];

export function UserDashboard({ user }: UserDashboardProps) {
  return (
    <div className="space-y-8">
      {/* Welcome Message */}
      <div className="glass-card p-8 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-indigo-600/10 to-purple-600/10"></div>
        <div className="relative">
          <h1 className="text-3xl font-bold mb-2">Welcome back, {user.username}</h1>
          <p className="text-gray-400">Let's find your next brand collaboration.</p>
        </div>
      </div>

      {/* Quick Stats */}
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-6">
        {[
          {
            icon: Award,
            title: 'Success Rate',
            value: '95%',
            trend: '+5%',
            description: 'Approval rate'
          },
          {
            icon: TrendingUp,
            title: 'Total Earned',
            value: '$1,250',
            trend: '+12%',
            description: 'This month'
          },
          {
            icon: Target,
            title: 'Completed',
            value: '12',
            trend: '+3',
            description: 'Campaigns'
          },
          {
            icon: Clock,
            title: 'Active Tasks',
            value: '3',
            trend: 'Due soon',
            description: 'In progress'
          }
        ].map((stat, index) => (
          <div key={index} className="glass-card p-6 relative overflow-hidden group hover:border-indigo-500/20 transition-colors">
            <div className="absolute inset-0 bg-gradient-to-br from-indigo-600/5 to-purple-600/5 opacity-0 group-hover:opacity-100 transition-opacity"></div>
            <div className="relative">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 rounded-lg bg-gradient-to-br from-indigo-500/20 to-purple-500/20">
                  <stat.icon className="w-5 h-5 text-indigo-400" />
                </div>
                <h3 className="text-lg font-semibold">{stat.title}</h3>
              </div>
              <div className="flex items-end justify-between">
                <div>
                  <p className="text-3xl font-bold gradient-text">{stat.value}</p>
                  <p className="text-sm text-gray-400 mt-1">{stat.description}</p>
                </div>
                <div className={`flex items-center gap-1 text-sm ${
                  stat.trend.includes('+') ? 'text-green-400' : 'text-indigo-400'
                }`}>
                  <TrendingUp className="w-4 h-4" />
                  {stat.trend}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Wallet Card */}
      <WalletCard
        balance={user.balance || 0}
        onRequestPayout={() => console.log('Request payout')}
        minPayoutAmount={10}
      />

      {/* Available Campaigns */}
      <div className="glass-card p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-xl font-bold mb-1">Available Campaigns</h2>
            <p className="text-sm text-gray-400">Matched to your profile and engagement rate</p>
          </div>
          <select className="px-4 py-2 bg-gray-800 rounded-lg border border-gray-700">
            <option>All Categories</option>
            <option>Fashion</option>
            <option>Beauty</option>
            <option>Technology</option>
          </select>
        </div>

        <CampaignList 
          campaigns={mockCampaigns}
          onAccept={(campaignId) => console.log('Applied to campaign:', campaignId)}
          compact
        />
      </div>

      {/* Active Tasks */}
      <div className="glass-card p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-xl font-bold mb-1">Your Active Tasks</h2>
            <p className="text-sm text-gray-400">Track your ongoing campaign tasks</p>
          </div>
        </div>
        <div className="text-center py-8">
          <div className="w-16 h-16 rounded-full bg-indigo-500/10 flex items-center justify-center mx-auto mb-4">
            <Target className="w-8 h-8 text-indigo-400" />
          </div>
          <p className="text-gray-400 mb-4">No active tasks yet. Apply to campaigns to get started!</p>
          <button className="button-primary mx-auto">
            Browse Campaigns
          </button>
        </div>
      </div>
    </div>
  );
}
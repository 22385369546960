import React, { useState } from 'react';
import { PaymentMethod } from '../../types/payment';
import { PaymentMethodList } from './PaymentMethodList';
import { PaymentMethodForm } from './PaymentMethodForm';
import { PaymentSummary } from './PaymentSummary';
import { ConfirmDialog } from '../shared/ConfirmDialog';
import { Plus, Wallet } from 'lucide-react';

interface PaymentDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (paymentMethodId: string) => Promise<void>;
  amount: number;
  paymentMethods: PaymentMethod[];
  isBrand?: boolean;
}

export function PaymentDialog({ 
  isOpen, 
  onClose, 
  onSubmit,
  amount,
  paymentMethods,
  isBrand = true
}: PaymentDialogProps) {
  const [selectedMethodId, setSelectedMethodId] = useState<string>(
    paymentMethods.find(m => m.isDefault)?.id || ''
  );
  const [showAddMethod, setShowAddMethod] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!selectedMethodId) return;
    
    try {
      setIsSubmitting(true);
      await onSubmit(selectedMethodId);
      onClose();
    } catch (error) {
      console.error('Payment error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      title={isBrand ? 'Complete Payment' : 'Request Payout'}
      message=""
      confirmText={isSubmitting ? 'Processing...' : 'Confirm'}
      confirmButtonClass="bg-indigo-600 hover:bg-indigo-500"
      showActions={!showAddMethod}
    >
      <div className="space-y-6">
        {/* Payment Summary */}
        <PaymentSummary amount={amount} isBrand={isBrand} />

        {showAddMethod ? (
          <>
            <h3 className="text-lg font-medium">Add Payment Method</h3>
            <PaymentMethodForm
              onSubmit={async (method) => {
                // In production, this would save to your backend
                console.log('New payment method:', method);
                setShowAddMethod(false);
              }}
              type="bank_transfer"
            />
          </>
        ) : (
          <>
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-medium">Payment Method</h3>
              <button
                onClick={() => setShowAddMethod(true)}
                className="flex items-center gap-1 text-sm text-indigo-400 hover:text-indigo-300"
              >
                <Plus className="w-4 h-4" />
                Add New
              </button>
            </div>

            <PaymentMethodList
              methods={paymentMethods}
              onSelect={(method) => setSelectedMethodId(method.id)}
              selectedId={selectedMethodId}
            />
          </>
        )}
      </div>
    </ConfirmDialog>
  );
}
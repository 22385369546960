import React from 'react';
import { PaymentMethod } from '../../types/payment';
import { Building2, CreditCard } from 'lucide-react';

interface PaymentMethodListProps {
  methods: PaymentMethod[];
  onSelect: (method: PaymentMethod) => void;
  selectedId?: string;
}

export function PaymentMethodList({ methods, onSelect, selectedId }: PaymentMethodListProps) {
  if (methods.length === 0) {
    return (
      <div className="text-center py-8 text-gray-400">
        No payment methods added yet.
      </div>
    );
  }

  return (
    <div className="space-y-3">
      {methods.map((method) => (
        <button
          key={method.id}
          onClick={() => onSelect(method)}
          className={`w-full flex items-center gap-4 p-4 rounded-lg transition-colors ${
            selectedId === method.id
              ? 'bg-indigo-500/10 border border-indigo-500/50'
              : 'bg-gray-800 hover:bg-gray-700 border border-transparent'
          }`}
        >
          {method.type === 'bank_transfer' ? (
            <Building2 className="w-6 h-6 text-indigo-400" />
          ) : (
            <CreditCard className="w-6 h-6 text-indigo-400" />
          )}
          
          <div className="flex-1 text-left">
            <p className="font-medium">
              {method.type === 'bank_transfer'
                ? `Bank Account (${method.details.accountNumber})`
                : `PayPal (${method.details.email})`}
            </p>
            {method.isDefault && (
              <p className="text-sm text-gray-400">Default payment method</p>
            )}
          </div>
        </button>
      ))}
    </div>
  );
}
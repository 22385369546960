import React from 'react';
import { NavLink } from 'react-router-dom';
import { LayoutDashboard, BarChart2, FileText, DollarSign } from 'lucide-react';
import { User } from '../../types';

interface DashboardNavProps {
  user: User;
}

export function DashboardNav({ user }: DashboardNavProps) {
  return (
    <nav className="glass-card p-4 mb-6">
      <ul className="flex gap-4">
        <li>
          <NavLink
            to="/dashboard"
            end
            className={({ isActive }) =>
              `flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                isActive
                  ? 'bg-indigo-500 text-white'
                  : 'text-gray-400 hover:text-white hover:bg-gray-800'
              }`
            }
          >
            <LayoutDashboard className="w-5 h-5" />
            <span>Overview</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/analytics"
            className={({ isActive }) =>
              `flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                isActive
                  ? 'bg-indigo-500 text-white'
                  : 'text-gray-400 hover:text-white hover:bg-gray-800'
              }`
            }
          >
            <BarChart2 className="w-5 h-5" />
            <span>Analytics</span>
          </NavLink>
        </li>
        {user.role === 'brand' && (
          <li>
            <NavLink
              to="/dashboard/campaigns"
              className={({ isActive }) =>
                `flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                  isActive
                    ? 'bg-indigo-500 text-white'
                    : 'text-gray-400 hover:text-white hover:bg-gray-800'
                }`
              }
            >
              <FileText className="w-5 h-5" />
              <span>Campaigns</span>
            </NavLink>
          </li>
        )}
        {user.role === 'promoter' && (
          <li>
            <NavLink
              to="/dashboard/earnings"
              className={({ isActive }) =>
                `flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                  isActive
                    ? 'bg-indigo-500 text-white'
                    : 'text-gray-400 hover:text-white hover:bg-gray-800'
                }`
              }
            >
              <DollarSign className="w-5 h-5" />
              <span>Earnings</span>
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
}
import { EscrowTransaction, PayoutRequest, PaymentMethod } from '../types/payment';

// Calculate platform fees
export const calculateFees = (amount: number) => {
  const brandFee = amount * 0.10;  // 10% fee for brands
  const promoterFee = amount * 0.05;  // 5% fee for creators
  
  return {
    brandFee,
    promoterFee,
    totalWithBrandFee: amount + brandFee,
    creatorPayout: amount - promoterFee
  };
};

// Create a new escrow transaction
export const createEscrowTransaction = async (
  campaignId: string,
  brandId: string,
  promoterId: string,
  amount: number,
  paymentMethod: PaymentMethod
): Promise<EscrowTransaction> => {
  const fees = calculateFees(amount);
  
  // In production, this would interact with your payment processor
  const transaction: EscrowTransaction = {
    id: `escrow-${Date.now()}`,
    campaignId,
    brandId,
    promoterId,
    amount,
    platformFeeBrand: fees.brandFee,
    platformFeePromoter: fees.promoterFee,
    status: 'pending',
    paymentMethod,
    createdAt: new Date().toISOString()
  };

  // Store transaction in database
  // await db.escrowTransactions.create(transaction);

  return transaction;
};

// Release funds to creator
export const releaseFunds = async (transactionId: string): Promise<EscrowTransaction> => {
  // In production, this would:
  // 1. Update transaction status
  // 2. Trigger payout to creator
  // 3. Update balances
  
  const transaction = {
    id: transactionId,
    status: 'released',
    releasedAt: new Date().toISOString()
  };

  // await db.escrowTransactions.update(transactionId, transaction);

  return transaction as EscrowTransaction;
};

// Request a payout
export const requestPayout = async (
  userId: string,
  amount: number,
  paymentMethod: PaymentMethod,
  transactionIds: string[]
): Promise<PayoutRequest> => {
  // In production, this would:
  // 1. Verify available balance
  // 2. Create payout request
  // 3. Queue for processing
  
  const payoutRequest: PayoutRequest = {
    id: `payout-${Date.now()}`,
    userId,
    amount,
    status: 'pending',
    paymentMethod,
    createdAt: new Date().toISOString(),
    transactionIds
  };

  // await db.payoutRequests.create(payoutRequest);

  return payoutRequest;
};
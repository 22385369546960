import React, { useState } from 'react';
import { PaymentMethod } from '../../types/payment';
import { CreditCard, Building2, AlertCircle } from 'lucide-react';

interface PaymentMethodFormProps {
  onSubmit: (method: Omit<PaymentMethod, 'id'>) => Promise<void>;
  type: 'bank_transfer' | 'paypal' | 'stripe';
}

export function PaymentMethodForm({ onSubmit, type }: PaymentMethodFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    const formData = new FormData(e.currentTarget);
    const details: Record<string, string> = {};
    
    formData.forEach((value, key) => {
      details[key] = value.toString();
    });

    try {
      await onSubmit({
        type,
        details,
        isDefault: formData.get('isDefault') === 'true'
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save payment method');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="flex items-center gap-2 text-red-400 bg-red-400/10 p-3 rounded-lg">
          <AlertCircle className="w-5 h-5" />
          <span>{error}</span>
        </div>
      )}

      {type === 'bank_transfer' && (
        <>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Account Holder Name
            </label>
            <input
              type="text"
              name="accountName"
              required
              className="w-full px-4 py-2 bg-gray-800 rounded-lg text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Bank Name
            </label>
            <input
              type="text"
              name="bankName"
              required
              className="w-full px-4 py-2 bg-gray-800 rounded-lg text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Account Number
            </label>
            <input
              type="text"
              name="accountNumber"
              required
              className="w-full px-4 py-2 bg-gray-800 rounded-lg text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Routing Number
            </label>
            <input
              type="text"
              name="routingNumber"
              required
              className="w-full px-4 py-2 bg-gray-800 rounded-lg text-white"
            />
          </div>
        </>
      )}

      {type === 'paypal' && (
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            PayPal Email
          </label>
          <input
            type="email"
            name="email"
            required
            className="w-full px-4 py-2 bg-gray-800 rounded-lg text-white"
          />
        </div>
      )}

      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          name="isDefault"
          id="isDefault"
          value="true"
          className="rounded bg-gray-800 border-gray-700 text-indigo-500"
        />
        <label htmlFor="isDefault" className="text-sm text-gray-300">
          Set as default payment method
        </label>
      </div>

      <button
        type="submit"
        disabled={isSubmitting}
        className="button-primary w-full"
      >
        {isSubmitting ? (
          <div className="flex items-center justify-center gap-2">
            <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
            Saving...
          </div>
        ) : (
          'Save Payment Method'
        )}
      </button>
    </form>
  );
}
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import { BrandDashboard } from '../components/dashboard/BrandDashboard';
import { UserDashboard } from '../components/dashboard/UserDashboard';

export function DashboardPage() {
  const { user } = useAuthStore();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {user.role === 'brand' ? (
        <BrandDashboard user={user} />
      ) : (
        <UserDashboard user={user} />
      )}
    </div>
  );
}
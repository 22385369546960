import React from 'react';
import { DollarSign, AlertCircle } from 'lucide-react';
import { calculateFees } from '../../lib/escrow';

interface PaymentSummaryProps {
  amount: number;
  isBrand?: boolean;
}

export function PaymentSummary({ amount, isBrand = false }: PaymentSummaryProps) {
  const fees = calculateFees(amount);

  return (
    <div className="space-y-4">
      <div className="flex items-start gap-2 text-yellow-400 bg-yellow-400/10 p-4 rounded-lg text-sm">
        <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
        <div>
          <p className="font-medium mb-1">Platform Fees</p>
          <p>
            {isBrand
              ? 'A 10% platform fee will be added to your payment to cover service costs.'
              : 'A 5% platform fee will be deducted from your earnings for service costs.'}
          </p>
        </div>
      </div>

      <div className="glass-card p-4 space-y-3">
        <div className="flex justify-between items-center text-gray-300">
          <span>Base Amount</span>
          <span>${amount.toFixed(2)}</span>
        </div>

        <div className="flex justify-between items-center text-gray-300">
          <span>Platform Fee ({isBrand ? '10%' : '5%'})</span>
          <span className="text-yellow-400">
            {isBrand 
              ? `+$${fees.brandFee.toFixed(2)}`
              : `-$${fees.promoterFee.toFixed(2)}`}
          </span>
        </div>

        <div className="border-t border-gray-700 pt-3 flex justify-between items-center">
          <span className="font-medium">Total</span>
          <span className="text-xl font-bold gradient-text">
            ${isBrand ? fees.totalWithBrandFee.toFixed(2) : fees.creatorPayout.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
}